<template>
  <div
    class="default-layout"
  >
    <LayoutTheHeader />
    <slot class="default-layout-content" :keep-alive="isKeepAlive" :keep-alive-props="{ max: 5, include: includedComponents, exclude: excludedComponents }" />
    <LazyLayoutTheFooter />
    <SharedCookieBanner />
  </div>
</template>

<script>
import { metaHreflang } from '@/utils/metaHreflang'
export default {
  setup () {
    const countriesApi = useCountries()
    return {
      countriesApi
    }
  },
  data () {
    return {
      includedComponents: ['CommunityHome', 'HelpSearchResult'],
      excludedComponents: [],
      hasKeepAlive: false
    }
  },
  head () {
    return {
      htmlAttrs: {
        lang: this.$route.meta.locale
      },
      link: [
        this.canonical(),
        ...this.hreflang()
      ]
    }
  },
  computed: {
    isKeepAlive () {
      return this.hasKeepAlive
    }
  },
  watch: {
    $route () {
      if (this.hasKeepAlive !== this.$route.meta.keepAlive) { this.hasKeepAlive = this.$route.meta.keepAlive }
    }
  },
  mounted () {
    this.hasKeepAlive = this.$route.meta.keepAlive
  },
  methods: {
    canonical () {
      return {
        rel: 'canonical',
        href: this.$env.websiteUrl + this.$route.path
      }
    },
    async hreflang () {
      if (this.$route.params.country) {
        const country = await this.countriesApi.getCountryBySlug(this.$route.params.country).alternativeSlugs.en
        return metaHreflang(this.$route, this.$router, country)
      }
      return metaHreflang(this.$route, this.$router)
    }
  }
}
</script>

<style lang="postcss" scoped>
.default-layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .default-layout-content {
    flex-grow: 1;
  }
}
</style>

<style>
*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
}
</style>
